export default [
  {
    title: 'Dashboard',
    icon: 'TrendingUpIcon',
    route: 'dashboard',
  },
  {
    title: 'Goals',
    icon: 'FlagIcon',
    route: 'goals',
  },
  {
    title: 'TV',
    icon: 'TvIcon',
    route: 'TV',
  },
]
